
import { cloneDeep } from 'lodash';

// For Admin
export const INITIAL_FORM = {
    name: '',
};

export const prepareInitialDataForPage = async (isEditCity, currentCity) => {
    if (isEditCity) {
        const { name, preOrder, preOrderMessage, active } = currentCity;

        let form = {
            name,
            preOrder,
            preOrderMessage,
            active,
        };

        return form;
    } else {
        return cloneDeep(INITIAL_FORM);
    }
};

export const prepareDataForRequest = (form, isEditCity) => {
    let formData = new FormData();

    let payload = {
        name: form.name,
        pre_order_message: form.preOrderMessage,
        pre_order: form.preOrder,
        active: form.active,
    };

    formData.append('payload', JSON.stringify(payload));

    if (isEditCity) {
        return payload;
    } else {
        return payload;
    }
};

export const clearForm = (form) => {
    Object.entries(form).forEach(([key, val]) => {
        form = cloneDeep(INITIAL_FORM);

        if (Array.isArray(val)) {
            val.length = 0;
            form[key] = val;
        }
    });

    return form;
};
