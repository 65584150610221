<template>
    <div class="create-blog-article" v-if="form">
        <h2>{{ $t('pages.city') }}</h2>
        
        <CRInput
            v-model="form.name"
            class="mb-20"
            required
            :label="$t('cities.name')"
            :placeholder="$t('placeholder.enterCityName')"
        />


        <CRInput
            v-model="form.preOrderMessage"
            class="mb-20"
            required
            :label="$t('cities.preOrderMessage')"
            :placeholder="$t('placeholder.enterCityPreOrderMessage')"
        />

        <CRCheckbox @change="preOrderChanged" :checked="form.preOrder">Įjungti išankstinį užsakymą</CRCheckbox>
        <br>
        <CRCheckbox @change="activeChanged" :checked="form.active">Įjungti miestą</CRCheckbox>
        <br>
        <CRButton class="mt-40" @click="createCity">
            {{ isEditCity ? $t('buttons.update') : $t('buttons.create') }}
        </CRButton>
    </div>
</template>

<script>
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    import {
        prepareInitialDataForPage,
        prepareDataForRequest,
        clearForm,
    } from '@/helpers/CityHelper';
    import { mapGetters } from 'vuex';
    import { CityApi } from '@/api';
    import { mapActions } from 'vuex/dist/vuex.cjs.js';

    export default {
        name: 'CreatePage',
        components: { CRInput, CRCheckbox, CRButton },
        data() {
            return {
                form: null,
                routerName: '',
            };
        },
        computed: {
            ...mapGetters('city', ['currentCity']),
            isEditCity() {
                return this.routerName === 'updateCity';
            },
        },
        watch: {
            $route: {
                async handler(value) {
                    this.routerName = value.name;
                    try {
                        this.form = await prepareInitialDataForPage(this.isEditCity, this.currentCity);
                    } catch (error) {
                        this.$filters.toast(error.message);
                    }
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions('city', ['getAllCities']),
            activeChanged() {
                this.form.active = !this.form.active;
            },
            preOrderChanged() {
                this.form.preOrder = !this.form.preOrder;
            },  
            async createCity() {
                try {
                    this.isLoading = true;

                    if (this.isEditCity) {
                        await CityApi.update(
                            prepareDataForRequest(this.form, this.isEditCity),
                            this.$route.params.city_id
                        );

                        this.getAllCities();
                    } else {
                        await CityApi.store(prepareDataForRequest(this.form, this.isEditCity));
                        this.getAllCities();
                    }
                    this.$router.push({ name: 'allAdminCities' });
                } catch (error) {
                    Object.entries(error.errors).forEach((element) => {
                        this.$filters.toast(element[1][0]);
                    });
                } finally {
                    this.isLoading = false;
                }
            },
        },

        beforeRouteLeave(to, from, next) {
            this.form = clearForm(this.form);
            next();
        },
    };
</script>

<style lang="scss" scoped>
    .create-blog-article {
        padding-bottom: 30px;
        max-width: 700px;

        h2 {
            margin: 20px 0;
        }

        .lang-buttons {
            margin-bottom: 30px;
        }
    }

    @include media($lg) {
        .create-blog-article {
            h2 {
                font-size: $font-34;
                margin-bottom: 45px;
            }
        }
    }
</style>
